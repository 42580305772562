import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import queryString from "query-string";
import toastr from "toastr";
import {
  CssBaseline,
  Grid,
  Typography,
  CircularProgress,
  Fade,
} from "@mui/material";

import {
  loginUser,
  useAppGlobalDispatch,
  useVrsTranslationState,
} from "../../context/AppContext/AppContext";
import { useSessionStorage } from "../../context/SessionStorageContext/SessionStorageContext";
import { Logger } from "../../utilities/Logger/Logger";
import { useLoginStyles } from "./Login.css";
import logo from "../../loginImages/my_login_logo.svg";
import { TermsDialog } from "../TermsDialog/TermsDialog";
import { NewPasswordDialog } from "../NewPasswordDialog/NewPasswordDialog";
import config from "../../config";
import { authModules } from "vccm-common";
import Utils from "../../utilities/utils";
import { callExternalApi } from "../../libs/apiLib";
import jwt from "jsonwebtoken";
import jwkToPem from "jwk-to-pem";
import LoginEnterUsername from "./LoginEnterUsername";
import LoginEnterPassword from "./LoginEnterPassword";
import { Cookies, useCookies } from "react-cookie";
import { useVrsUserActions } from "../../actions/vrsUserActions";
import { useCompanyActions } from "../../actions/companyActions";
import { useNavigateToLink } from "../../actions/NavigateActions";
import { extractErrors } from "../../libs/getDynoErrors";

const loginBreaks: any = {
  image: {
    lg: 8,
    md: 7,
    sm: 12,
    xs: 12,
  },
  login: {
    lg: 4,
    md: 5,
    sm: 12,
    xs: 12,
  },
};
const cognitoIDPAuthenticator = `https://cognito-idp.${config.cognito.REGION}.amazonaws.com/${config.cognito.USER_POOL_ID}`;

enum LoginState {
  EnterUsername,
  EnterPassword,
}

interface IAuthDetails {
  Username?: string;
  Password?: string;
  getUsername: any;
  getPassword: any;
  getValidationData: any;
}

const getDomain = () => {
  if (window.location.host.toLowerCase().includes("localhost")) {
    return "localhost";
  }

  if (window.location.host.toLowerCase().includes("videojetcloud.com")) {
    return "videojetcloud.com";
  }

  return "videojet.com";
};

const displayCognitoTriggerError = (error: any) =>
  `${error.payload.email} ${error.message}`;

function Login() {
  const navigate = useNavigateToLink();
  const location = useLocation();
  const classes = useLoginStyles();

  const [, setCookie, removeCookie] = useCookies(["ReturnUrlSearch"]);

  const inputReference = useRef<any>(null);

  const companyActions = useCompanyActions();

  const { token } = useParams<{ token: string }>();
  const { _T } = useVrsTranslationState();

  const vrsUserActions = useVrsUserActions();

  const queryStringObj = queryString.parse(location.search);
  const email: string =
    queryStringObj && queryStringObj.Email
      ? (queryStringObj.Email as string)
      : "";
  const code = (queryStringObj && queryStringObj.code) as string;

  const errorCode = (queryStringObj &&
    queryStringObj.error_description) as string;
  const errorQs = (queryStringObj && queryStringObj.error) as string;
  const userSessionStorage = useSessionStorage();
  const userDispatch = useAppGlobalDispatch();
  const [error, setError] = useState(false);
  const [username, setUsername] = useState(email);
  const [password, setPassword] = useState("");
  const [authenticationDetails, setAuthenticationDetails] =
    useState<IAuthDetails>();

  const [authError, setAuthError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [, setAccepted] = useState(false);
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [loginState, setLoginState] = useState<LoginState>(
    LoginState.EnterUsername
  );
  const [retryLogin, setRetryLogin] = useState(false);

  const setUser = useCallback(
    (userProfile: any) => {
      loginUser(
        userDispatch,
        userSessionStorage,
        userProfile,
        setIsLoading,
        setError
      );
    },
    [userSessionStorage, userDispatch]
  );

  // DE-3481 disable terms and conditions for everyone -- will likely come back with DE-3439
  // useEffect(() => {
  //   if (newPasswordRequired) {
  //     setShowTermsModal(true);
  //     setAccepted(false);
  //   }
  // }, [newPasswordRequired]); // eslint-disable-line react-hooks/exhaustive-deps
  //
  // useEffect(() => {
  //   if (accepted) {
  //     setShowTermsModal(false);
  //     setShowNewPasswordModal(newPasswordRequired);
  //   }
  // }, [accepted, newPasswordRequired]);

  useEffect(() => {
    setShowNewPasswordModal(newPasswordRequired);
  }, [newPasswordRequired]);

  useEffect(() => {
    if (inputReference && inputReference.current) {
      inputReference.current.focus();
    }
  }, []);

  interface ILoginResult {
    loggedIn: boolean;
    userEmail: string;
    userPassword: string;
  }

  const login = async (
    userEmail: string,
    userPassword: string
  ): Promise<ILoginResult> => {
    const userPool = new CognitoUserPool({
      UserPoolId: config.cognito.USER_POOL_ID,
      ClientId: config.cognito.APP_CLIENT_ID,
    });
    const user = new CognitoUser({
      Username: userEmail,
      Pool: userPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: userEmail,
      Password: userPassword,
    });
    Logger.of("Login.login").info(`for ${userEmail}`);

    return new Promise((resolve, reject) =>
      user.authenticateUser(authDetails, {
        onSuccess: (result) => {
          Logger.of("Login.login").info("logged in successful", result);
          resolve({
            loggedIn: true,
            userEmail,
            userPassword,
          });
        },
        newPasswordRequired: () => {
          setNewPasswordRequired(true);
          setAuthenticationDetails(authDetails);
          setIsLoading(false);
          setError(false);
          setAuthError("");
        },
        onFailure: (err) => {
          Logger.of("Login.login").info("got an error =>", err);
          reject(err);
        },
      })
    );
  };

  // wrapped because it is an effect dependency
  const handleNewPasswordSubmit = useCallback(async () => {
    const userPool = new CognitoUserPool({
      UserPoolId: config.cognito.USER_POOL_ID,
      ClientId: config.cognito.APP_CLIENT_ID,
    });
    const user = new CognitoUser({
      Username: username.toLowerCase().trim(),
      Pool: userPool,
    });
    Logger.of("Login.handleNewPasswordSubmit").info(
      "userPool =>",
      userPool,
      authenticationDetails
    );
    if (!authenticationDetails) {
      return Promise.reject(false);
    }

    return new Promise((resolve, reject) =>
      user.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          Logger.of("Login.handleNewPasswordSubmit").info(
            "logged in successful",
            result
          );
          resolve(true);
        },
        newPasswordRequired: () => {
          Logger.of("Login.handleNewPasswordSubmit").info(
            "new password required 3"
          );

          user.completeNewPasswordChallenge(newPassword, null, {
            onSuccess: (result) => {
              Logger.of("Login.handleNewPasswordSubmit").info(
                "success",
                result
              );
              setShowNewPasswordModal(false);
              setError(false);
              setPassword("");
              setIsLoading(false);
            },
            onFailure: (error) => {
              setAuthError(error.message);
              setIsLoading(false);
              Logger.of("Login.handleNewPasswordSubmit").info(
                "error",
                error.message
              );
              toastr.error(error.message, "New Password", { timeOut: 10000 });
              reject(error);
            },
          });
        },
        onFailure: (error) => {
          Logger.of("Login.handleNewPasswordSubmit").info(
            "got an error =>",
            error
          );
          setAuthError(error.message);
          setIsLoading(false);
          toastr.error(
            error(error.message, "Password Reset", { timeOut: 10000 })
          );
          throw error;
        },
      })
    );
  }, [authenticationDetails, username, newPassword]);

  // the password component will set a newpassword to submit
  useEffect(() => {
    if (newPassword !== "") {
      handleNewPasswordSubmit().then(() =>
        Logger.of("Login.useEffect newPassword").info("submitted")
      );
    }
  }, [handleNewPasswordSubmit, newPassword]);

  // presignup api throwing an error on success and the client will need to retry SSO
  useEffect(() => {
    try {
      if (errorQs === "server_error") {
        const jsonTxtExtract = /{.*}/;
        const results = jsonTxtExtract.exec(errorCode);
        if (results && results.index >= 0) {
          const error = JSON.parse(results[0]);
          if (error?.retryable && error?.payload?.email != null) {
            setUsername(error?.payload?.email);
            setRetryLogin(true);
          } else if (error != null && error?.retryable !== true) {
            setAuthError(displayCognitoTriggerError(error));
          }
        }
      }
    } catch (e) {
      setAuthError(e.toString());
    }
  }, [errorQs, errorCode]);

  const loginFinish = useCallback(
    async ({ loggedIn, userEmail, userPassword }: ILoginResult) => {
      let returnUrlSearch = new Cookies().get("ReturnUrlSearch");
      const { ReturnUrl } = returnUrlSearch
        ? queryString.parse(returnUrlSearch)
        : { ReturnUrl: "" };
      if (ReturnUrl) {
        returnUrlSearch = "/";
      }
      setIsLoggedIn(loggedIn);
      setError(!loggedIn);
      setIsLoading(false);
      setPassword(userPassword);

      const plantId = Utils.getPlantCookie();

      const userProfile = {
        email: userEmail,
        selectedSiteId: plantId === "0" ? "" : plantId,
      };

      setUser(userProfile);

      const searchStr = returnUrlSearch
        ? returnUrlSearch
        : window.location.search;

      const { TrafficSource, TrafficID, SFDCRecord } =
        Utils.getTrackingValuesFromQueryParameters(searchStr);

      const q: any = queryString.parse(searchStr);

      if (TrafficSource === "SFDC") {
        let accessType = "Unknown";
        if (q?.targetUrl?.includes("vrs/device")) {
          accessType = "Device";
        } else if (q?.targetUrl?.includes("vrs/sitedashboard")) {
          accessType = "Site";
        }
        try {
          const result = await vrsUserActions.saveSalesforceTrackerAppSync(
            TrafficSource,
            TrafficID,
            SFDCRecord,
            accessType
          );
          const saveResult = result?.data;
          if (saveResult) {
            console.log("saved to salesforce");
          } else if (result.error) {
            const errStr = extractErrors(result.error);
            if (errStr) {
              toastr.error(errStr);
            }
          }
        } catch (error) {
          const errStr = extractErrors(error);
          toastr.error(errStr || error);
        }
      }

      if (q.ciffName && q.ciffId && searchStr && searchStr.includes("/editor.html#")) {
        const currentDesignTemplate = `editor.html#?ciffName=${q.ciffName.replace(' ', '%20')}&ciffId=${q.ciffId.replace(
          ' ',
          '%20'
        )}`;
        window.open(currentDesignTemplate, '_self');
      } else if (q.targetUrl && searchStr) {
        navigate(`${q.targetUrl}${searchStr}`, { replace: true });
      } else if (q.targetUrl) {
        navigate(q.targetUrl, { replace: true });
      }

      removeCookie("ReturnUrlSearch", { path: "/", domain: getDomain() });
    },
    [setUser, navigate, removeCookie]
  );

  const loginWithUserDetails = async () => {
    setIsLoading(true);
    await login(username.toLowerCase().trim(), password.trim())
      .then(loginFinish)
      .catch((error) => {
        const errorMessage =
          error.message ===
            "Temporary password has expired and must be reset by an administrator."
            ? _T("Incorrect username or password.")
            : error.message;
        setAuthError(errorMessage);
        setIsLoading(false);
      });
  };

  const onNext = useCallback(
    async (bypassSSO: boolean) => {
      const SSOUrl = Utils.getSSOUrl(username);
      const searchStr = window.location.search ? window.location.hash ? `${window.location.search}${window.location.hash}` : window.location.search : "/";
      const params = queryString.parse(searchStr);
      if (params.targetUrl) {
        setCookie(
          "ReturnUrlSearch",
          searchStr,
          {
            domain: getDomain(),
            expires: new Date(new Date().getTime() + 30 * 60000),
            path: "/",
            secure: true,
            encode: (value) => value, // do not encode
          }
        );

        if (params.targetUrl) {
          const { companyId, siteId } =
            Utils.getTrackingValuesFromQueryParameters(window.location.search);

          if (companyId) {
            if (
              !Utils.IsIdGuid(companyId) &&
              (siteId === "0" || siteId === "")
            ) {
              Utils.setCompanyCookie(companyId);
              companyActions.setVrsCompanyAndSiteId(companyId, "0");
            } else if (
              !Utils.IsIdGuid(companyId) &&
              siteId !== "0" &&
              siteId !== ""
            ) {
              Utils.setCompanyAndPlantCookie(companyId, siteId);
              companyActions.setVrsCompanyAndSiteId(companyId, siteId);
            }
          }
        }
      } else {
        removeCookie("ReturnUrlSearch", { path: "/", domain: getDomain() });
      }

      if (SSOUrl.length === 0 || bypassSSO) {
        setLoginState(LoginState.EnterPassword);
      } else {
        setIsLoading(true);
        window.open(SSOUrl, "_self");
      }
    },
    [companyActions, removeCookie, setCookie, token, username]
  );

  // simple retry login
  useEffect(() => {
    (async () => {
      if (retryLogin) {
        setRetryLogin(false);
        await onNext(false);
      }
    })();
  }, [retryLogin, onNext]);

  const onBack = async () => {
    setLoginState(LoginState.EnterUsername);
  };

  const onCloseTermsDialog = (hasUserAccepted) => {
    setShowTermsModal(false);
    setAccepted(hasUserAccepted);
    if (!hasUserAccepted) {
      setNewPasswordRequired(false);
    }
  };

  const onCloseNewPasswordDialog = (newPassword) => {
    if (newPassword) {
      setNewPassword(newPassword);
      setIsLoading(true);
    } else {
      setNewPasswordRequired(false);
      setShowNewPasswordModal(false);
    }
  };

  useEffect(() => {
    const module = "Login.OAUTH";

    Logger.of(module).info("code effect", {
      code,
      isLoading,
      isLoggedIn,
      error,
    });

    if (!code || isLoading || isLoggedIn || error) {
      return;
    }
    setIsLoading(true);
    const logic = async () => {
      const body = `grant_type=authorization_code&client_id=${config.cognito.APP_CLIENT_ID
        }&code=${code}&redirect_uri=${encodeURIComponent(
          Utils.getRedirectUrl(Utils.getStage())
        )}`;
      const { status, data } = await callExternalApi(
        "POST",
        config.cognito.OATH_TOKEN_URL + "/token",
        body,
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );

      if (status !== 200) {
        setError(true);
        setIsLoading(false);
        setAuthError(data.error);
        Logger.of(module).warn(data.error);
        return;
      }
      const key = authModules.getCognitoKeys(config.cognito.USER_POOL_ID);
      try {
        // https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-verifying-a-jwt.html#amazon-cognito-user-pools-using-tokens-step-2
        const verifiedIdToken = jwt.verify(
          data.id_token,
          jwkToPem(key?.keys[0]),
          {
            algorithms: ["RS256"],
            aud: config.cognito.APP_CLIENT_ID,
            iss: cognitoIDPAuthenticator,
            token_use: "id",
          }
        );
        jwt.verify(data.access_token, jwkToPem(key?.keys[1]), {
          algorithms: ["RS256"],
          aud: config.cognito.APP_CLIENT_ID,
          iss: cognitoIDPAuthenticator,
          token_use: "access",
        });
        const userPool = new CognitoUserPool({
          UserPoolId: config.cognito.USER_POOL_ID,
          ClientId: config.cognito.APP_CLIENT_ID,
        });
        const user = new CognitoUser({
          Username: verifiedIdToken["cognito:username"],
          Pool: userPool,
        });
        const cognitoUserSession = new CognitoUserSession({
          IdToken: new CognitoIdToken({ IdToken: data.id_token }),
          AccessToken: new CognitoAccessToken({
            AccessToken: data.access_token,
          }),
          RefreshToken: new CognitoRefreshToken({
            RefreshToken: data.refresh_token,
          }),
        });
        user.setSignInUserSession(cognitoUserSession);

        await loginFinish({
          loggedIn: true,
          userEmail: verifiedIdToken && verifiedIdToken.email,
          userPassword: "",
        });
        Logger.of(module).info("loginFinish TOKEN", {
          email: verifiedIdToken.email,
        });
      } catch (e) {
        Logger.of(module).warn(e);
        setError(true);
        setAuthError(e?.message || e);
        setIsLoading(false);
      }
    };

    logic().then(() => Logger.of("Login.OAUTH").info("finished"));
  }, [code, loginFinish, isLoading, isLoggedIn, error]);

  return (
    <>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item {...loginBreaks.image} xs={false} className={classes.image}>
          <img id="logo" className={classes.logo} src={logo} alt="logo" />
        </Grid>
        <Grid item {...loginBreaks.login} elevation={6}>
          {isLoading ? (
            <div className={classes.paper}>
              <CircularProgress />
            </div>
          ) : (
            <div className={classes.paper}>
              {loginState === LoginState.EnterUsername && (
                <LoginEnterUsername
                  isLoading={isLoading}
                  username={username}
                  setUsername={setUsername}
                  onNext={onNext}
                />
              )}
              {loginState === LoginState.EnterPassword && (
                <LoginEnterPassword
                  isLoading={isLoading}
                  username={username}
                  password={password}
                  setPassword={setPassword}
                  onBack={onBack}
                  loginWithUserDetails={loginWithUserDetails}
                  setAuthError={setAuthError}
                  setError={setError}
                />
              )}
              <Grid container>
                <Grid item xs>
                  <Fade in={error || !!authError}>
                    <Typography className={classes.errorMessage}>
                      {authError ? authError : error ? _T("LoginError") : ""}
                    </Typography>
                  </Fade>
                </Grid>
              </Grid>
              <div id="warning-txt" className="warning">
                {error}
              </div>
            </div>
          )}
        </Grid>
      </Grid>
      <TermsDialog open={showTermsModal} onClose={onCloseTermsDialog} />
      {showNewPasswordModal && (
        <NewPasswordDialog
          loading={isLoading}
          open={showNewPasswordModal}
          onClose={onCloseNewPasswordDialog}
        />
      )}
    </>
  );
}

Login.displayName = "Login";

export default Login;
